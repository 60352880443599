import { graphql } from 'gatsby';
import * as React from 'react';

import { formatInfoLinks, formatMenuLinks } from '../../utils/helpers';
import { IArticlesIndexProps } from '../../utils/types';

import { ArticlesIndexComponent } from './ArticlesIndexComponent';

const ArticlesIndexTemplate = ({
  data,
  location,
  pageContext,
}: IArticlesIndexProps): React.ReactElement => {
  const { nodes } = data.allContentfulArticle;
  const {
    title,
    subHeading,
    featuredImage,
    headerType,
    description,
    sectionId,
    meta,
    pageContent,
    tiles,
    clickToAction,
    clickToActionPrimaryButton,
  } = data.contentfulPage;

  // instead of adding a new query for the referenzen category just to query the language we can use
  // the language passed in the pageContext and it will work for both normal articlesIndex pages and the referenzen category.

  const menuLink = formatMenuLinks(
    data.allContentfulCategory.nodes,
    pageContext.language
  );
  const infoLink = formatInfoLinks(
    data.allContentfulPrivacyAndToS.nodes,
    pageContext.language
  );

  return (
    <ArticlesIndexComponent
      menuLinks={menuLink}
      infoLinks={infoLink}
      articles={nodes}
      staticLocation={location}
      pageContext={pageContext}
      title={title}
      subHeading={subHeading}
      featuredImage={featuredImage}
      headerType={headerType}
      description={description}
      sectionId={sectionId}
      meta={meta}
      pageContent={pageContent}
      tiles={tiles}
      clickToAction={clickToAction}
      clickToActionPrimaryButton={clickToActionPrimaryButton}
      language={pageContext.language}
      vacationBanner={data.contentfulVacationBanner}
    />
  );
};

export const query = graphql`
  query ArticlesIndexTemplateQuery(
    $slug: String
    $articleType: String
    $language: String
  ) {
    allContentfulCategory(
      sort: { fields: order }
      filter: { language: { eq: $language } }
    ) {
      nodes {
        ...category
      }
    }

    allContentfulArticle(
      filter: { articleType: { eq: $articleType }, language: { eq: $language } }
    ) {
      nodes {
        title
        slug
        description {
          description
        }
        linkTitle
        featuredImage {
          title
          gatsbyImageData(quality: 90, width: 2560, placeholder: BLURRED)
        }
        tags {
          title
        }
      }
    }

    contentfulPage(slug: { eq: $slug }) {
      title
      subHeading
      language
      description {
        description
      }
      headerType
      sectionId
      tiles {
        ...tileElementfragment
      }
      clickToAction {
        title
        subTitle
        href: link
      }
      clickToActionPrimaryButton {
        title
        subTitle
        href: link
      }
      featuredImage {
        title
        gatsbyImageData(
          quality: 100
          width: 2560
          layout: CONSTRAINED
          breakpoints: [450, 760, 1024, 1296, 1440, 1920, 2560]
          sizes: "(max-width: 450px) 450px,(max-width: 768px) 760px, (max-width:1023px) 1024px, (max-width: 1439px) 1440px, (max-width: 1920px) 1920px, 2560px"
          placeholder: BLURRED
          aspectRatio: 1
        )
      }
      description {
        description
      }
      meta {
        name
        content
      }
      pageContent {
        raw
        references {
          ...allBlocks
        }
      }
    }

    allContentfulPrivacyAndToS(filter: { language: { eq: $language } }) {
      nodes {
        title
        language
        url: slug
      }
    }

    contentfulVacationBanner {
      ...vacationBanner
    }
  }
`;

export default ArticlesIndexTemplate;
