import * as React from 'react';

import {
  CardsContainer,
  HeadMeta,
  HomeHero,
  ICardProps,
  IClickToActionProps,
  IInfoLink,
  IMenuLink,
  ITileProps,
  Layout,
  MainHero,
  ReferencesHero,
  RichTextRender,
  SmallHero,
} from '../../components';
import { LanguageContext } from '../../context/LanguageContext';
import { RichTextElements } from '../../utils/fragments/types';
import { FilteredElements } from '../../utils/helpers';
import {
  IArticleInfo,
  IContentfulVacationBanner,
  ICustomGatsbyImageData,
  ILocation,
  IMetaTagField,
} from '../../utils/types';

import * as styles from './ArticlesIndex.module.less';

interface IArticlesIndexComponentProps {
  title: string;
  articles: IArticleInfo[];
  menuLinks: IMenuLink[];
  infoLinks: IInfoLink[];
  pageContext: { articleType: string };
  language: 'De' | 'En';
  staticLocation?: ILocation;
  subHeading?: string;
  headerType?: string;
  featuredImage?: ICustomGatsbyImageData;
  description?: {
    description: string;
  };
  sectionId?: string;
  pageContent?: {
    raw: string;
    references: RichTextElements[];
  };
  tiles?: ITileProps[];
  clickToAction?: IClickToActionProps;
  clickToActionPrimaryButton?: IClickToActionProps;
  meta?: IMetaTagField[];
  vacationBanner?: IContentfulVacationBanner;
}

export const ArticlesIndexComponent: React.FunctionComponent<IArticlesIndexComponentProps> = (
  props
) => {
  const referenceHero = (
    <ReferencesHero
      title={props.language === "En" ? 'References' : 'Referenzen'}
      mainPost={{
        title: props.articles[0]?.title,
        featuredImage: props.articles[0]?.featuredImage,
        tags: props.articles[0]?.tags,
        link: {
          slug: props.articles[0]?.slug,
          title: ' Mehr Erfahren',
        },
      }}
      location={props.staticLocation}
    />
  );

  const regularHero = props.headerType
    ? {
      'Home Hero': (
        <HomeHero
          title={props.title}
          image={props.featuredImage}
          subTitle={props.subHeading}
        />
      ),
      'Small Hero': (
        <SmallHero
          title={props.title}
          featuredImage={props.featuredImage}
          subheading={props.subHeading}
          location={props.staticLocation}
          description={props.description?.description}
        />
      ),
      'Main Hero': (
        <MainHero
          title={props.title}
          image={props.featuredImage}
          description={props.description}
          sectionId={props.sectionId}
          location={props.staticLocation}
          tiles={props.tiles || null}
          clickToActionTile={props.clickToAction}
          clickToActionPrimaryButton={props.clickToActionPrimaryButton}
        />
      ),
    }[props.headerType]
    : null;

  const articlesCards: ICardProps[] = props.articles.map(
    ({ title, slug, description, featuredImage, tags, linkTitle, date }) => ({
      title,
      description,
      image: featuredImage,
      tags,
      internalLink: { slug: slug, title: linkTitle || title, url: slug },
      date,
    })
  );

  const referenceCards = (
    <div className={styles.wrapper}>{FilteredElements(articlesCards)}</div>
  );

  const regularCards = <CardsContainer elements={articlesCards} />;

  const cardsSection =
    props?.pageContext?.articleType === 'Reference'
      ? referenceCards
      : regularCards;

  return (
    <LanguageContext.Provider value={props.language}>
      <Layout
        links={props.menuLinks}
        infoLinks={props.infoLinks}
        vacationBanner={props.vacationBanner}
      >
        <HeadMeta
          title={
            props?.pageContext?.articleType === 'Reference'
              ? props.language === 'En' ? 'References' : 'Referenzen'
              : props.title
          }
          meta={
            props?.pageContext?.articleType === 'Reference'
              ? props.articles[0]?.meta
              : props?.meta
          }
          lang={props.language.toLowerCase()}
        />
        {props?.pageContext?.articleType === 'Reference'
          ? referenceHero
          : regularHero}

        {cardsSection}

        {props?.pageContent &&
          props?.pageContext?.articleType != 'Reference' && (
            <RichTextRender content={props.pageContent} />
          )}
      </Layout>
    </LanguageContext.Provider>
  );
};
